














import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private window: any = window;
  private Top: any = 0;
  private QRCode: any;
  private creatQrCode() {
    let qrcode = new this.QRCode("qrcode", {
      width: 200, //图像宽度
      height: 200, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: this.QRCode.CorrectLevel.H,
    });
    qrcode.clear(); //清除二维码
    qrcode.makeCode(this.$store.state.user.doctor_id); //生成另一个新的二维码
  }
  mounted() {
    this.creatQrCode();
    this.Top = (document.body.clientHeight - 50 - 14 - 14) / 2 - 160;
  }
}
